import React from "react"
import { graphql, Link } from "gatsby"
import Post from "../components/article"
import Layout from "../components/layout"
import FeaturedImage from "../components/featuredImage"
import Image from "gatsby-image"
import { dateFormatDistance } from "../utils/date"

export default function Component(props) {
  const article = props.data.markdownRemark
  const frontmatter = article.frontmatter
  const created = new Date(frontmatter.created)

  return <Layout>
    <div>
      {article.frontmatter?.featuredImage?.uri &&
        <FeaturedImage
        image={frontmatter.featuredImage.uri}
        creditLabel={frontmatter.featuredImageCreditLabel}
        creditUri={frontmatter.featuredImageCreditUri }
        displayCredits={true}
        alt=""
      />    
      }

      <div className="container mx-auto p-2">
      <Post post={article} />
      <div className="container mx-auto p-2 sm:p-0 sm:grid sm:grid-cols-4 gap-2 mt-4">
        {props.data.allFile.edges.map(
          (edge) => <a href={edge.node.childCloudinaryAsset.fixed.src}>
            <div className="pt-2"><Image fluid={edge.node.childCloudinaryAsset.fluid} /></div>
          </a>
        )}
      </div>
      <div className="text-center font-bold">
      {console.log(typeof props.pageContext.previousTimeStamp)}
      {console.log( props.pageContext.previousTimeStamp)}
        {props.pageContext.previousSlug && <Link to={props.pageContext.previousSlug}>{"< "}{dateFormatDistance(created,new Date(props.pageContext.previousTimeStamp))} plus tôt</Link>}
        {(props.pageContext.previousSlug && props.pageContext.nextSlug) && " - "}
        {props.pageContext.nextSlug && <Link to={props.pageContext.nextSlug}>{dateFormatDistance(created,new Date(props.pageContext.nextTimeStamp))} plus tard {">"}</Link>}

      </div>
      </div>

    </div>

  </Layout>
}

export const query = graphql`
    query($id: String, $galleryDirectory: String) {
      markdownRemark(id: { eq: $id }) {
          id
          frontmatter {
            title
            created
            featuredImage {
              id
              uri {
                childCloudinaryAsset {
                  fluid {
                    ...CloudinaryAssetFluid
                  }
                } 
                publicURL
                }
                name {
                  relativePath
                } 
              }
              featuredImageCreditLabel
              featuredImageCreditUri
              published
              season
              section
              slug
              tags
            }
          headings {
            id
          }
          html
    }
    allFile(filter: {relativeDirectory: {eq: $galleryDirectory}}) {
      edges {

        node {
          childCloudinaryAsset {
            fluid {
              ...CloudinaryAssetFluid
              ...CloudinaryAssetFluidLimitPresentationSize
            }
            fixed {
              src
            }
          }
          relativeDirectory
          relativePath
          name
        }
      }
    }
  }
`
